var achievement_content = new Swiper('.ttc-achievement-2 .content-slider', {
	slidesPerView: 1,
	loop: false,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	noSwiping: true
});

var achievement_img = new Swiper('.ttc-achievement-2 .img-slider', {
	slidesPerView: 3,
	centeredSlides: true,
	spaceBetween: -100,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	loop: false,
	navigation: {
		nextEl: '.img-slider .swiper-button-next',
		prevEl: '.img-slider .swiper-button-prev',
	},
	breakpointsInverse: true,
	breakpoints: {
		768: {
			spaceBetween: -400
		}
	},
	thumbs: {
		swiper: achievement_content
	}
});

var gallery_small_thumbnail = new Swiper('.ttc-gallery-1 .small-thumbnail', {
	slidesPerView: 3,
	freemode: true,
	loop: false,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	breakpointsInverse: true,
	breakpoints: {
		768: {
			slidesPerView: 5
		},
		1200: {
			slidesPerView: 6
		}
	},
});
var gallery_big_thumbnail = new Swiper('.ttc-gallery-1 .big-thumbnail', {
	slidesPerView: 1,
	loop: false,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	thumbs: {
		swiper: gallery_small_thumbnail
	}
});

var product_small_thumbnail = new Swiper('.ttc-product-details-1 .small-thumbnail .swiper-container', {
	slidesPerView: 2,
	freemode: true,
	loop: false,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	breakpointsInverse: true,
	spaceBetween: 0,
	navigation: {
		nextEl: '.ttc-product-details-1 .swiper-button-next',
		prevEl: '.ttc-product-details-1 .swiper-button-prev',
	},
	breakpoints: {
		576: {
			slidesPerView: 3,
			spaceBetween: 15
		},
		768: {
			slidesPerView: 4,
			spaceBetween: 15
		},
		992: {
			slidesPerView: 5,
			spaceBetween: 15
		},
		1200: {
			slidesPerView: 5,
			spaceBetween: 0
		}
	},
});

var product_big_thumbnail = new Swiper('.ttc-product-details-1 .big-thumbnail', {
	slidesPerView: 1,
	loop: false,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	thumbs: {
		swiper: product_small_thumbnail
	}
})



const topnav = new MappingListener({
	selector: '.top-left-list',
	mobileWrapper: ".mobile-top-left-nav",
	mobileMethod: "appendTo",
	desktopWrapper: ".top-left-nav",
	desktopMethod: "appendTo",
	breakpoint: 992,
}).watch();
try {

} catch (error) {

}

const language = new MappingListener({
	selector: '.language',
	mobileWrapper: ".logo-wrapper",
	mobileMethod: "insertAfter",
	desktopWrapper: ".top-right-nav",
	desktopMethod: "appendTo",
	breakpoint: 992,
}).watch();

const search_box = new MappingListener({
	selector: '.searchbox',
	mobileWrapper: ".nav",
	mobileMethod: "insertBefore",
	desktopWrapper: ".language",
	desktopMethod: "insertAfter",
	breakpoint: 992,
}).watch();



//Jquery Ready Document
$(function () {
	$(".ttc-videos-1 .video-item").fancybox()
	$(".ttc-chart-1 .chart-fancy-box").fancybox()
	$(document).on('click', '.menu-toggle', function (e) {
		$('.ttc-header-1 .menu-wrapper').toggleClass('active')
		$(e.currentTarget).toggleClass('active')
	})

	$('.news-detail-page .Module-234').remove();
	let newsOtherMappingHtml = $('.news-detail-page .news-other-mapping').detach();
	$('.news-other-wrapper').html(newsOtherMappingHtml);

	adjustHeader();

	$(document).on('scroll', adjustHeader);

	$('.dropdown .dd-toggle').click(function (e) {
		e.preventDefault();
		$(this).parent().next().toggleClass('active')
		$(this).parents('.dropdown').siblings('.dropdown').children('.dd-menu').removeClass('active')
	});

	var isScrolled = true
	$(document).on('scroll', function () {
		if (isScrolled) {
			if ($('.ttc-home-3').length) {
				if ($(document).scrollTop() >= $('.ttc-home-3').offset().top - 200) {
					countUp()
					isScrolled = false
				}
			}
		}
	});


	$('.rating-and-cart .rating').barrating({
		theme: 'css-stars'
	});

	$("input.quantity").TouchSpin({})

});
//End Jquery Ready Document

function adjustHeader() {
	// Check if document has scrolled at all
	if ($(document).scrollTop() > 1 && $(window).width() > 992)
		$('.ttc-header-1').addClass('fixed');
	else
		$('.ttc-header-1').removeClass('fixed');
}

const product_special = tns({
	container: '.product-special-slider',
	items: 1,
	controls: true,
	mouseDrag: true,
	loop: false,
	gutter: 30,
	nav: false,
	controlsContainer: '.product-special-slider-controls',
	responsive: {
		450: {
			items: 2
		},
		992: {
			items: 3
		}
	}
})
const product_other = tns({
	container: '.product-other-slider',
	items: 1,
	controls: true,
	mouseDrag: true,
	loop: false,
	gutter: 30,
	nav: false,
	controlsContainer: '.product-others-slider-controls',
	responsive: {
		480: {
			items: 2
		},
		992: {
			items: 4
		}
	}
})

const year_nav = tns({
	container: '.year-nav',
	items: 3,
	controls: false,
	mouseDrag: true,
	loop: false,
	nav: false,
	slideBy: 1,
	// controlsContainer: ".year-nav-wrapper-controls"
})

const product_similar = tns({
	container: '.product-similar-slider',
	items: 1,
	controls: true,
	mouseDrag: true,
	loop: false,
	gutter: 30,
	nav: false,
	controlsContainer: '.product-similar-slider-controls',
	responsive: {
		480: {
			items: 2
		},
		992: {
			items: 4
		}
	}
})

const homebanner = tns({
	container: '.home-banner',
	items: 1,
	controls: false,
	mouseDrag: true,
	loop: true
})

const ttc_home_1_slider = tns({
	container: '.ttc-home-1-slider',
	items: 1,
	controls: true,
	nav: false,
	mouseDrag: true,
	loop: false,
	gutter: 0,
	controlsContainer: '.ttc-home-1-slider-controls',
	responsive: {
		576: {
			items: 2,
			gutter: 5
		}
	}
})

const partner_slider = tns({
	container: '.ttc-home-5 .lists-item',
	items: 2,
	controls: true,
	nav: false,
	mouseDrag: true,
	loop: true,
	gutter: 14,
	controlsContainer: '.partner-slider-controls',
	responsive: {
		576: {
			items: 3
		},
		768: {
			items: 4
		},
		992: {
			items: 5,
		},
		1200: {
			items: 6,
		}
	}
})







function countUp() {
	$('[count-up]').each(function () {
		let $this = $(this)
		let countTo = $this.attr('data-count')
		let duration = Number($this.attr('data-duration'))
		let append = $this.attr('data-after')
		let separator = $this.attr('data-seperator')
		$({
			countNum: $this.text()
		}).animate({
			countNum: countTo
		}, {
			duration: duration,
			easing: 'linear',
			step: function () {
				$this.text(Math.floor(this.countNum));
			},
			complete: function () {
				let result = this.countNum.toLocaleString('en-US')
				if (separator != null) {
					result = result.replace(/,/g, separator)
				}
				if (append == null) {
					$this.html(result)
				} else {
					$this.html(result + append);
				}

			}

		});
	});
}